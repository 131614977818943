import React from 'react';
import styled from 'styled-components';
import { TrackedWorkout } from '../../types/adminPortalApiSchema';
import { formatDateTime } from '../../utils/utils';
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeader,
} from '../Theme/Theme';
import { trackUserWorkout } from '../TrackUserWorkout/trackUserWorkoutRequest';
import WorkoutWatchedStatus from './WorkoutWatchedStatus';

type Props = {
  workouts: (TrackedWorkout & { name?: string })[];
  userId: string;
  getClientPageData: () => Promise<void>;
};

const TrackedWorkoutTable: React.FC<Props> = ({
  workouts,
  userId,
  getClientPageData,
}) => {
  const handleSetAsWatched = async (item: TrackedWorkout) => {
    await trackUserWorkout(item.workoutId, item.workoutDuration, userId);

    await getClientPageData();
  };
  return (
    <StyledTable>
      <thead>
        <tr>
          <StyledTableHeader>Date time</StyledTableHeader>
          <StyledTableHeader>Workout ID</StyledTableHeader>
          <StyledTableHeader>Trainer</StyledTableHeader>
          <StyledTableHeader>Watched/Duration (seconds)</StyledTableHeader>
        </tr>
      </thead>
      <tbody>
        {workouts.map((trackedWorkout) => {
          const percentageWatched = Math.round(
            (trackedWorkout.secondsWatched / trackedWorkout.workoutDuration) *
              100
          );
          return (
            <tr key={`${trackedWorkout.sessionId}_${trackedWorkout.workoutId}`}>
              <StyledTableCell>
                {formatDateTime(trackedWorkout.timestamp)}
              </StyledTableCell>
              <StyledTableCell>
                <a
                  href={`https://app.contentful.com/spaces/450abldm8aui/entries/${trackedWorkout.workoutId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {trackedWorkout.name || trackedWorkout.workoutId}
                </a>
              </StyledTableCell>
              <StyledTableCell>{trackedWorkout.trainer}</StyledTableCell>
              <StyledTableCell
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {trackedWorkout.secondsWatched}/{trackedWorkout.workoutDuration}{' '}
                ({percentageWatched}%){' '}
                {!trackedWorkout.workoutIsWatched && (
                  <StyledSmallButton
                    onClick={() => handleSetAsWatched(trackedWorkout)}
                    title="This will create a new workout watch record which will appear at the top of this table. This will effect badges and challenges for applicable workouts"
                  >
                    Watch
                  </StyledSmallButton>
                )}
                <WorkoutWatchedStatus
                  isWatched={trackedWorkout.workoutIsWatched}
                />
              </StyledTableCell>
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

const StyledSmallButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: #0053c7;
  color: white;
  padding: 4px;
  border-radius: 4px;
`;
export default TrackedWorkoutTable;
