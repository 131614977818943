import { queryAPI } from '../../utils/queryApi';

const query = /* GraphQL */ `
  mutation($input: TrackUserWorkoutInput!) {
    trackUserWorkout(trackUserWorkoutInput: $input) {
      fractionWatched
      secondsWatched
      workoutId
      workoutIsComplete
    }
  }
`;

export const trackUserWorkout = async (
  workoutId: string,
  durationSeconds: number,
  userId: string
) => {
  const variables = {
    input: {
      workoutId,
      workoutDuration: durationSeconds,
      userId,
    },
  };

  await queryAPI(query, variables);
};
