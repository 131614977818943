import { InlineLoading, Search } from 'carbon-components-react';
import React, { FormEvent, Fragment, useState } from 'react';
import styled from 'styled-components';
import {
  BCStructuredList,
  BCStructuredListItem,
} from '../BCStructuredList/BCStructuredList';
import { StyledBlueButton, StyledButton } from '../Button/Button';
import Modal from '../Modal/Modal';
import {
  SearchCMSWorkoutResultItem,
  searchCmsWorkouts,
} from './searchCmsWorkouts';
import { trackUserWorkout } from './trackUserWorkoutRequest';

interface Props {
  userId: string;
  getClientPageData: () => Promise<void>;
}

const TrackUserWorkout: React.FC<Props> = ({ userId, getClientPageData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showTrackWorkoutForm, setShowTrackWorkoutForm] = useState(false);
  const [workoutSearchTerm, setWorkoutSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<
    SearchCMSWorkoutResultItem[]
  >([]);
  const [selectedWorkout, setSelectedWorkout] = useState<
    SearchCMSWorkoutResultItem | undefined
  >();

  const handleSearchSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    const res = await searchCmsWorkouts(workoutSearchTerm);
    setIsLoading(false);
    setSearchResults(res);
    setSelectedWorkout(undefined);
  };

  const searchResultsList: BCStructuredListItem<
    SearchCMSWorkoutResultItem
  >[] = searchResults.map((searchResult) => {
    return {
      value: `${searchResult.name} (${searchResult.internalName})`,
      text: `${searchResult.sys.id.slice(0, 8)}...`,
      meta: searchResult,
    };
  });

  const handleTrackWorkoutClick = async () => {
    if (!selectedWorkout) {
      throw new Error('Select a workout to track');
    }
    await trackUserWorkout(
      selectedWorkout.sys.id,
      selectedWorkout.duration * 60,
      userId
    );
    await getClientPageData();
    setShowTrackWorkoutForm(false);
    setWorkoutSearchTerm('');
    setSearchResults([]);
  };

  return (
    <Fragment>
      <StyledButton onClick={() => setShowTrackWorkoutForm(true)}>
        Manually Add Workout
      </StyledButton>
      <Modal
        isOpen={showTrackWorkoutForm}
        onClose={() => setShowTrackWorkoutForm(false)}
      >
        <Fragment>
          <StyledHeader>
            <StyledTitle>Manually Add a Workout</StyledTitle>
            {isLoading && <InlineLoading status="active" />}
          </StyledHeader>
          <StyledExplainer>
            Use this to manually add a workout watch for a user. For applicable
            workouts this will affect badges and/or challenge completion.
          </StyledExplainer>
          <form onSubmit={handleSearchSubmit}>
            <Search
              labelText="Search for a workout to add"
              placeHolderText="Search for a workout to add"
              onChange={(event) => setWorkoutSearchTerm(event.target.value)}
              value={workoutSearchTerm}
            />
            <StyledBlueButton type="submit">Search</StyledBlueButton>
          </form>

          {!selectedWorkout && (
            <BCStructuredList
              params={searchResultsList}
              onClick={(item) =>
                setSelectedWorkout(item.meta as SearchCMSWorkoutResultItem)
              }
            />
          )}
          {!!selectedWorkout && (
            <Fragment>
              <p>You have selected:</p>
              <StyledSelectedWorkout>
                {selectedWorkout.name} ({selectedWorkout.internalName}) -{' '}
                {selectedWorkout.sys.id}
              </StyledSelectedWorkout>
              <StyledBlueButton onClick={handleTrackWorkoutClick}>
                Add
              </StyledBlueButton>
            </Fragment>
          )}
        </Fragment>
      </Modal>
    </Fragment>
  );
};

const StyledTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSelectedWorkout = styled.p`
  margin-top: 16px;
  font-weight: bold;
`;

const StyledExplainer = styled.p`
  margin-bottom: 32px;
`;

export default TrackUserWorkout;
